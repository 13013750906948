import { ContentfulDiarioInternacional } from '../../../types/graphql-types';
import parseDate from '../../utils/parseDate';
import { DiarioInternacional as DiarioInternacionalProps } from '../../models/DiarioInternacional';
import { Tag as TagProps } from '../../models/Tag';
import { DiarioInternacionalPais as DiarioInternacionalPaisProps } from '../../models/DiarioInternacionalPais';
// import { Author } from '../../models/Author';
// import { Table as TableProps } from '../../models/Table';

export const useDiarioInternacionalPost = (
  data: ContentfulDiarioInternacional
): DiarioInternacionalProps => {
  return {
    id: data.id,
    title: data.titulo ? data.titulo : undefined,
    description: data.resumen ? data.resumen : undefined,
    category: 'Internacional',
    content: 'Diario Internacional',
    categorySlug: 'internacional',
    slug: data.slug,
    contentSlug: 'diario-internacional',
    date: data.createdAt ? parseDate(data.createdAt) : 'Fecha de publicación',
    fluid: data.heroImage,
    // fluid2: data.fotoDeApoyo,
    authorObject: {
      // autor, el que va en el PostLayout
      id: data.autor?.id,
      nombre: data.autor?.nombre,
      twitter: data.autor?.twitter,
      bio: data.autor?.bio,
      avatar: data.autor?.foto,
      puesto: data.autor?.puesto,
    },
    subheaderEUA: data.subheaderEUA,
    textEUA: data.textoEUA,
    imagesEUA: data?.imagenEUA,
    subheaderEurozona: data.subheaderEurozona,
    textEurozona: data.textoEurozona,
    imagesEurozona: data?.imagenEurozona,
    subheaderUk: data.subheaderUk,
    textUk: data.textoUk,
    imagesUk: data?.imagenUk,
    subheaderJpn: data.subheaderJapn,
    textJpn: data.textoJapn,
    imagesJpn: data?.imagenJapn,
    subheaderChina: data.subheaderChina,
    textChina: data.textoChina,
    imagesChina: data?.imagenChina,
    tags: data.tags
      ? data.tags.map((tag: TagProps) => {
          return {
            nombre: tag.nombre,
            slug: tag.slug,
          };
        })
    : null,
    diarioInternacionalPaises: data.diarioInternacionalPais
      ? data.diarioInternacionalPais.map((DiarioInternacionalPais: DiarioInternacionalPaisProps) => {
          return {
            subheaderPais: DiarioInternacionalPais.subheaderPais,
            textoPais: DiarioInternacionalPais.textoPais,
            imagenPais: DiarioInternacionalPais.imagenPais,
          };
        })
    : null,
  };
};
import React from 'react';
import { graphql } from 'gatsby';

import { Grid } from '@material-ui/core';
import { ContentfulDiarioInternacional } from '../../types/graphql-types';
import { useDiarioInternacionalPost } from '../operations/queries/diarioInternacionalPost';
import PostLayout from '../components/PostLayout';
import { PostPreview as PostPreviewProps } from '../models/PostPreview';
import { DiarioInternacional as DiarioInternacionalProps } from '../models/DiarioInternacional';
import IconTitle from '../components/IconTitle';
import { useTheme } from '@material-ui/core/styles';

import WorldMarkets from '../assets/icons/icon-WorldMarkets.svg';

import useMediaQuery from '@material-ui/core/useMediaQuery';

import getCategoryColor from '../utils/getCategoryColor';

import { renderRichText } from "gatsby-source-contentful/rich-text"

import options from '../utils/getRichTextOptions';

import USA from '../assets/icons/usa-flag.svg';
import CHINA from '../assets/icons/china-flag.svg';
import UK from '../assets/icons/uk-flag.svg';
import Euro from '../assets/icons/eurozona-flag.svg';
import JPN from '../assets/icons/japon-flag.svg';
import StickyBox from 'react-sticky-box';
import useIsClient from '../utils/isClient';
import { HeadApi } from '../components/HeadApi';


interface Props {
  data: {
    contentfulDiarioInternacional: ContentfulDiarioInternacional;
  };
}

export const Head = ({data}) => {
  const post: DiarioInternacionalProps = useDiarioInternacionalPost(
    data.contentfulDiarioInternacional
  );
  // console.log("[POST HEAD]", post);
  return(
    <HeadApi
      title={post.title}
      description={post.description}
      img={post.fluid?.gatsbyImageData.images.fallback.src}
    />
  );
};

export default function DiarioInternacional({ data }: Props): JSX.Element {
  const post: DiarioInternacionalProps = useDiarioInternacionalPost(
    data.contentfulDiarioInternacional
    );
    // console.log(post)

  const postPreview = (): PostPreviewProps => {
    const {
      title,
      description,
      categorySlug,
      category,
      slug,
      date,
      fluid,
      author,
      authorObject,
      content,
      tags,
      diarioInternacionalPaises
    } = post;

    return {
      title,
      description,
      categorySlug,
      category,
      slug,
      date,
      fluid,
      author,
      authorObject,
      content,
      tags,
      diarioInternacionalPaises
    };
  };

  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const color = getCategoryColor('internacional');

  // console.log('post diario econ');
  // console.log(post);

  const isClient = useIsClient();

  if(isClient){

    return (
      <>
        <PostLayout post={postPreview()} color={color}>
          <>
            {post.description && (
              <h2
                style={{
                  paddingRight: '10px',
                  paddingLeft: '10px',
                  fontSize: '1.15em',
                }}
              >
                {post.description}
              </h2>
            )}
            <div>
              <ul>
                {/* <li>
                  <a href="#usa-section">usa</a>
                </li> */}
                {/* <li></li>
                <li></li>
                <li></li>
                <li></li>
                <li></li> */}
              </ul>
            </div>
            <>
              <Grid container>
                
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  style={mobile ? { zIndex: 0, background: 'white' } : {}}
                >
                  <div id="usa-section">
                    {post.textEUA && (
                      <IconTitle title="Estados Unidos" icon={USA}>
                        {renderRichText(post.textEUA, options)}
                      </IconTitle>
                    )}
                  </div>
  
                  {post.imagesEUA && (
                    <div style={{ marginTop: '30px', marginBottom: '30px', width:"50%" }}>
                      <img src={post.imagesEUA.gatsbyImageData.images.fallback.src} alt="" />
                    </div>
                  )}
  
                  {post.textEurozona && (
                    <IconTitle title="Eurozona" icon={Euro}>
                      {renderRichText(post.textEurozona, options)}
                    </IconTitle>
                  )}
  
                  {post.imagesEurozona && (
                    <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                      <img src={post.imagesEurozona.gatsbyImageData.images.fallback.src} alt="" />
                    </div>
                  )}
  
                  {post.textUk && (
                    <IconTitle title="Reino Unido" icon={UK}>
                      {renderRichText(post.textUk, options)}
                    </IconTitle>
                  )}
                  {post.imagesUk && (
                    <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                      <img src={post.imagesUk.gatsbyImageData.images.fallback.src} alt="" />
                    </div>
                  )}
  
                  {post.textJpn && (
                    <IconTitle title={"Japón"} icon={JPN}>
                      {renderRichText(post.textJpn, options)}
                    </IconTitle>
                  )}
                  {post.imagesJpn && (
                    <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                      <img src={post.imagesJpn.gatsbyImageData.images.fallback.src} alt="" />
                    </div>
                  )}
  
  
                  {post.textChina && (
                    <IconTitle title="China" icon={CHINA}>
                      {renderRichText(post.textChina, options)}
                    </IconTitle>
                  )}
  
                  {post.imagesChina && (
                    <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                      <img src={post.imagesChina.gatsbyImageData.images.fallback.src} alt="" />
                    </div>
                  )}
  
  
  
  
                  {post.diarioInternacionalPaises && 
                    
                    post.diarioInternacionalPaises.map(function (node:any) {
                      return (
                        <>
                          <IconTitle title={node.subheaderPais} icon={WorldMarkets}>
                            {renderRichText(node.textoPais, options)}
                          </IconTitle>
                        
                        {node.imagenPais &&
                          <div style={{ marginTop: '30px', marginBottom: '30px' }}>
                            <img src={node.imagenPais.gatsbyImageData.images.fallback.src} alt="" />
                          </div>
                        }
                        </>
                      );
                    })
                    
                  }
                  
                </Grid>
              </Grid>
            </>
          </>
        </PostLayout>
      </>
    );
  }else{
    return(<></>)
  }

}

export const pageQuery = graphql`
  query($slug: String!) {
    contentfulDiarioInternacional(slug: { eq: $slug }) {
      ...diarioInternacional
    }
  }
`;